export const lawyers = {
  "律师id":"id",
  "姓名":"name",
  "部门": "dname",
  "执业证编号":"practiceno",
  "性别":"gender",
  "身份证号":"idCard",
  "手机号码":"phone",
  "职业年限":"practicingYears",
  "政治面貌":"politicalStatus",
  "民族":"nationality",
  "学历":"degree",
  "专业":"specialty",
  "注册时间":"createTime",
}

//todo 导出对应字段要改
export const transcript = {
  "律师姓名":"name",
  "所在律所": "dname",
  "执业证编号":"practiceno",
  "联系方式":"phone",
  "职业道德学时":"eightScore",
  "职业道德达标状态":"eightScoreStatus",
  "其他培训学时":"trainScore",
  "培训达标状态":"trainScoreStatus",
  "公益学时":"serviceScore",
  "公益达标状态":"serviceScoreStatus",


}
export const LAudited = {
  "审核日期":"updateTime",
  "申报人": "username",
  "所属律所":"dname",
  "申报类型":"type",
  "申报学时/时长":"score",
  "审核结果":"status",
  "审核意见":"info",
  "审核人":"aname",

}
